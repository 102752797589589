import { useState, useEffect } from 'react';
import { getStore } from '../firebase';
import { getDateTime } from '../utils/date';
import { getAdmins, isAdmin } from './admin';

const USERS = 'users';

export const useFetchUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadUsers = async () => {
      setLoading(true);
      try {
        const users = await getAllUsers();
        if (users.length) {
          setUsers(users);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
    loadUsers();
  }, []);

  return [ users, loading ];
}

export const generateUserDocument = async (userData: { 
  uid: string,
  email: string, 
  firstName: string,
  lastName: string,
  gender: string
 }) => {
  if (!userData) return;
  const { db, collection, doc, query, where, getDocs, setDoc, serverTimestamp } = await getStore();

  try {
    return setDoc(doc(collection(db, USERS), userData.uid), {
      createdAt: serverTimestamp(),
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      gender: userData.gender,
      photoURL: null,
      phoneNumber: null,
      uid: userData.uid,
    })
  } catch (error) {
    console.error('Error creating user document', error);
  }
};

export const getUserDocument = async (uid) => {
  if (!uid) return;
  const { db, doc, getDoc } = await getStore();

  const userRef = doc(db, 'users', uid)
  const snapshot = await getDoc(userRef);
  if (snapshot.exists()) {
    // console.log(snapshot);
    const userData = snapshot.data();
    return { ...userData, id: snapshot.id, isAdmin: await isAdmin(userData.email) };
  }

  return null;
};

export const getAllUsers = async () => {
  const { db, query, collection, getDocs } = await getStore();
  const admins = await getAdmins();

  const q = query(collection(db, USERS));
  return (await getDocs(q)).docs.map((doc) => {
    const userData = doc.data();
    return {
      ...userData,
      id: doc.id,
      isAdmin: admins.includes(userData.email),
    };
  });
};

export const updateUserDocument = async ({ isAdmin, id, ...userData }) => {
  const { db, doc, updateDoc } = await getStore();
  const userRef = doc(db, USERS, id)
  return updateDoc(userRef, userData)
}

export const getUserByEmail = async (email) => {
  const { db, query, collection, limit, where, getDocs } = await getStore();
  const q = query(collection(db, USERS), where('email', '==', email), limit(1))
  const userData =  (await getDocs(q))
  if (userData.docs.length > 0) {
    return userData.docs[0].data()
  }
  return null
}

export const getUsersByEmail = async (emailsArray) => {
  const { db, query, collection, where, getDocs } = await getStore();

  const q = query(collection(db, USERS), where('email', 'in', emailsArray))
  const userData =  await getDocs(q)
  return userData.docs;
}
